import * as React from "react"
import { Link, graphql } from "gatsby"
import { pipesToSpan } from "../util/common"
import Image from "./Image"

const Hero = ({ title, description, cta, image, relationships, bgColor = '', noImage = false }) => {

  const titleHtml = pipesToSpan(title)

  //console.log(relationships.image)


  const d = {
    titleHtml: titleHtml,
    title: title,
    description: description,
    cta: cta,
    image: image,
    relationships: relationships,
  }

  return (

    <div className={"pt-32 pb-12  lg:pt-48 lg:pb-32 " + bgColor}>


      {!noImage && <WithImage {...d} />}
      {noImage && <WithOutImage {...d} />}

      {/*         <div className=" lg:w-1/2">
          <h1 className="text-5xl lg:text-6xl" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          <h4 className="my-10 font-normal">{description}</h4>
          <Link to={cta.uri} className="rounded-full text-sm bg-blue text-white font-bold py-3.5 px-11">{cta.title}</Link>
        </div>
        <div className="lg:w-1/2 text-center mt-12 lg:mt-0">
          <Image image={relationships.image} alt={image.alt} className="" />
        </div> */}

    </div>

  )
}


export const HeroFront = ({ title, description, cta, image, relationships, bgColor = '' }) => {

  const titleHtml = pipesToSpan(title)

  return (

    <div className={"pt-32 pb-12  lg:pt-48 lg:pb-0 " + bgColor}>

      <div className="container flex flex-col lg:flex-row lg:justify-center">
        <div className=" lg:w-3/4 text-center">
          <h1 className="text-4xl lg:text-6xl lg:leading-[1.3] mb-12" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          <Link to={cta.uri} className="rounded-full text-sm lg:text-base bg-blue text-white font-bold inline-block w-auto	 py-3.5 px-11 hero_cta">{cta.title}</Link>
          {/* <h4 className="mt-8 lg:mt-16 mx-auto font-meduim text-2xl lg:text-3xl  lg:max-w-[821px]" dangerouslySetInnerHTML={{ __html: pipesToSpan(description) }} /> */}
        </div>

      </div>

    </div>

  )
}



export const HeroInner = ({ title, description, cta, image, relationships, tag = false, bgColor = '', noImage = false,
classNameTitle = '',
classNameImage = 'max-w-[640px]',
iframe = false
}) => {

  const titleHtml = pipesToSpan(title)


  return (
    <>
      <div className={"pt-32 pb-16  lg:pt-44 " + bgColor}>

        <div className="container text-center">
          <div className="">
            {tag && <div className="font-bold text-base lg:text-2xl pb-6 lg:pb-16  text-[22px]">{tag}</div>}
            <h1 className={"text-4xl lg:text-6xl lg:leading-[1.3] " + classNameTitle} dangerouslySetInnerHTML={{ __html: titleHtml }} />
            <h2 className="my-10 text-lg lg:text-2xl lg:leading-9	 font-normal lg:w-2/3 mx-auto" dangerouslySetInnerHTML={{ __html: description }} />
            {/* <Link to={cta.uri} className="rounded-full text-sm bg-blue text-white font-bold py-3.5 px-11 w-full lg:w-auto border block lg:inline hero_cta">{cta.title}</Link> */}
            <Link 
            to={cta.uri} 
            className="border  rounded-lg px-[30px] py-3 font-medium leading-[20px] text-[#2D3643] bg-white --bg-[#F7F9FA] hover:bg-[#F7F9FA]  --hover:bg-[#F0F3F9]  hero_cta"
            >{cta.title}</Link>
          </div>
        </div>
      </div>

      {!noImage &&
      <div className="text-center pb-6  lg:pb-6 mt-0 lg:mt-0 ">
        <div className="relative">
          <div className={" mx-auto " + classNameImage } >
            <Image image={relationships.image} alt={image.alt} loading="eager" width={image?.width} height={image?.height} className="" />
          </div>
          <div className="absolute inset-x-0 top-0  -z-10 h-full ">
            <div className={" h-1/2 " + bgColor}></div>
            <div className=" h-1/2 bg-white"></div>
          </div>
        </div>
      </div>
      }


      {iframe && noImage && <div className="text-center pb-6  lg:pb-6 mt-0 lg:mt-0 ">
        <div className="relative bg-grey1">
          <section className="lg:px-32 pb-6">
            <div className="px-0 lg:px-6">
              <div
                className="h-[400px] lg:h-[800px] "
                dangerouslySetInnerHTML={{ __html: iframe }}
              />
            </div>
          </section>
        </div>
      </div>
      }
    </>
  )
}

export const HeroWhy = ({ title, description, cta, image, relationships, tag = false, bgColor = '', noImage = false }) => {

  const titleHtml = pipesToSpan(title)

  return (
    <div className={"pt-32 pb-12  lg:pt-44  " /*+ bgColor*/} style={{background: "linear-gradient(to bottom, #FFFFFF 34.38%, #FAFAFD 100%)"}}>

      <div className="container  text-center ">
        <div className="mb-12 lg:mb-0">
          {tag && <div className="font-bold text-base lg:text-2xl pb-6 lg:pb-16  text-[22px]">{tag}</div>}
          <h1 className="text-4xl lg:text-6xl lg:leading-[1.3]" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          <h2 className="my-10 text-lg lg:text-2xl lg:leading-9	 font-normal lg:w-2/3 mx-auto " dangerouslySetInnerHTML={{ __html: description }} />
          <Link to={cta.uri} className="rounded-full text-sm lg:text-base bg-blue text-white font-bold py-3.5 px-11 w-full lg:w-auto border block lg:inline hero_cta">{cta.title}</Link>
        </div>
      </div>

      {!noImage &&
        <div className="text-center pb-6  mt-0 lg:mt-8 ">
          <div className="lg:max-w-[986px] lg:h-[429px] mx-auto ">
            <Image image={relationships.imageBig} alt={image.alt} loading="eager"  className="" />
          </div>
        </div>
      }
    </div>

  )
}


const WithImage = ({ title, titleHtml, description, cta, image, relationships }) => {

  return (
    <div className="container flex flex-col lg:flex-row lg:space-x-11">
      <div className=" lg:w-1/2 lg:pl-24">
        <h1 className="text-5xl lg:text-6xl" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        <h2 className="my-10 font-normal">{description}</h2>
        <Link to={cta.uri} className="rounded-full text-sm bg-blue text-white font-bold py-3.5 px-11 hero_cta">{cta.title}</Link>
      </div>
      <div className="lg:w-1/2 text-center mt-12 lg:mt-0">
        {/* <img src={relationships.image.localFile.url} alt={image.alt} /> */}
        <Image image={relationships.image} alt={image.alt} loading="eager" className="" />
      </div>
    </div>
  )
}

const WithOutImage = ({ title, titleHtml, description, cta, image, relationships }) => {
  return (
    <div className="container flex flex-col lg:flex-row lg:justify-center">
      <div className=" lg:w-1/2 text-center">
        <h1 className="text-5xl lg:text-6xl" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        <h2 className="my-10 mx-8 font-normal">{description}</h2>
        <Link to={cta.uri} className="rounded-full text-sm bg-blue text-white font-bold py-3.5 px-11 hero_cta">{cta.title}</Link>
      </div>

    </div>
  )
}


// export const HeroGlossaryTermInner = ({ title, description = false, tag = false, bgColor = '#06C673' }) => {

//   const titleHtml = pipesToSpan(title)

//   return (
//     <div className={" pt-32 pb-16 lg:pb-28 lg:pt-44 "} style={{ backgroundColor: '#015CE4', borderBottom: "20px solid", borderColor: bgColor }}>
//       <div className="container  text-center ">
//         <div className="text-white">
//           {tag && <div className="font-bold text-base lg:text-2xl pb-6 lg:pb-16  text-[22px] uppercase">{tag}</div>}
//           <h1 className="text-4xl lg:text-6xl" dangerouslySetInnerHTML={{ __html: titleHtml }} />
//           {description && <h2 className="my-10 text-lg lg:text-2xl lg:leading-9	 font-normal lg:w-2/3 mx-auto">{description}</h2>}
//         </div>
//       </div>
//     </div>
//   )
// }


export const HeroGlossary = ({ title, description = false, tag = false, bgColor = '#F2F2F5', children }) => {

  const titleHtml = pipesToSpan(title)

  return (
    <div className={" pt-32 pb-16 lg:pb-28 lg:pt-44 "} style={{ backgroundColor: bgColor }}>
      <div className="container  text-center ">
        <div className="">
          {tag && <div className="font-bold text-base lg:text-2xl pb-6 lg:pb-16  text-[22px] uppercase">{tag}</div>}
          <h1 className="text-4xl lg:text-6xl lg:leading-[1.3]" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          {description && <h2 className="my-10 text-lg lg:text-2xl lg:leading-9	 font-normal lg:w-2/3 mx-auto">{description}</h2>}
          <div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}




export const CareersHero = ({ title, /* titleHtml, */ description, /* description_html: { processed }, */ image, relationships, ...props }) => {

  const titleHtml = pipesToSpan(title)

  return (
    <section className="pt-32 pb-12  lg:pt-48 lg:pb-32 ">
      <div className="container flex flex-col text-center ">
        <div className="lg:w-2/3 mx-auto">
          <h1 className="text-5xl lg:text-6xl" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          <h2 className="my-10 text-2xl lg:mx-32 font-normal leading-9	">{description}</h2>
        </div>
      </div>
      <div className="text-center lg:pt-8 lg:pb-20 mt-0 lg:mt-0 mx-auto lg:bg-[url('/background/careers-hero.png')]  lg:bg-top lg:bg-no-repeat  ">
        <Image image={relationships.imageBig} alt={image.alt} loading="eager" />
      </div>
    </section>
  )
}

export default Hero


export const query = graphql`
  fragment HeroContent on paragraph__hero {
    id
    description: field_description
    title: field_hero_title
    cta: field_cta {
      title
      uri
    }
    image: field_image {
      alt
      title
      height
      width
    }
    relationships {
      image: field_image {
        ...Image
      }
      imageBig: field_image {
        ...ImageBig
      }
    }
  }
`
